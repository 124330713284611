// ** React Imports
import {createContext, useEffect, useState} from 'react'

// ** Intl Provider Import
import {IntlProvider} from 'react-intl'
import moment from "moment"

// ** Create Context
const Context = createContext()

const IntlProviderWrapper = ({children}) => {
  // ** States
  const [localeLoaded, setLocaleLoaded] = useState(false)
  const [locale, setLocale] = useState(localStorage.getItem('selectedLang'))
  const [menuMessages, setMenuMessages] = useState({
    "en-US": {}
  })
  const [messages, setMessages] = useState(menuMessages['en-US'])

  // ** Switches Language
  const switchLanguage = lang => {
    setMessages(prevMessages => {
      setLocale(prevLang => {
        const locale = lang.split('-')[0]
        moment.locale(locale)

        localStorage.setItem('selectedLang', lang)

        return lang
      })

      return menuMessages[lang]
    })
  }

  useEffect(() => {
    window.api.loadLanguages()
      .then((data) => {
        if (data.data.status === 'success') {
          window.translations = data.data.data.languages

          setMenuMessages(prevMenuMessages => {
            setMessages(data.data.data.languages[localStorage.getItem('selectedLang')])
            setLocaleLoaded(true)
            return data.data.data.languages
          })
        }

        if (data.data.data.languages[localStorage.getItem('selectedLang')] === undefined) {
          localStorage.setItem('selectedLang', 'en-US')
        }
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  if (localeLoaded === false) {
    return <span></span>
  }

  const onIntlKeyError = (err) => {
    const errString = err.toString()
    if (errString.includes('Missing message')) {
      console.log(errString)
    }
  }
  const storageLang = localStorage.getItem('selectedLang')

  return (
    <Context.Provider value={{locale, switchLanguage, menuMessages}}>
      <IntlProvider key={locale} locale={locale} messages={messages} defaultLocale={storageLang ? storageLang : 'en-US'} onError={onIntlKeyError}>
        {children}
      </IntlProvider>
    </Context.Provider>
  )
}

export {IntlProviderWrapper, Context as IntlContext}
