// ** React Imports
import {updateAppDataAction} from "@store/actions/updateAppDataAction"
import {store} from "@store/storeConfig/store"

export const useUpdateAppData = () => {
  return function () {
    return window.api.get('user/appData')
      .then(function (appDataResult) {
        appDataResult = appDataResult.data

        if (appDataResult.status === 'success') {
          store.dispatch(updateAppDataAction(appDataResult.data))
        } else {
          // TODO May be token expired or something else. Handle this.
          console.log(appDataResult)
          if (appDataResult.exceptionType === "UserNotLoggedInException") {
            localStorage.removeItem('token')
          }
        }
      })
      .catch(err => {
        console.log(err)
      })
  }
}
